import React, { useContext } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import styles from "./timeline-jss";
import { TranslationContext } from "../../context";

const AdSection = ({ classes }) => {
  const { t } = useContext(TranslationContext);

  return (
    <Paper
      style={{
        padding: "16px",
        position: "sticky",
        top: "5rem",
      }}
    >
      <Typography variant="h6">{t("timeline.Advertisement")}</Typography>
      <Box className={classes.adsContainer}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              height={300}
              image="https://images.unsplash.com/photo-1519677704001-6d410c3ef07e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="green iguana"
            />
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              height={300}
              image="https://images.unsplash.com/photo-1550335865-16a340d45467?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="green iguana"
            />
          </CardActionArea>
        </Card>
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(AdSection);
