import i18n from "./i18n";

function numberTranslator(number, i18nInstance = null) {
  try {
    const numberStr = number.toString();
    let translatedNumber = '';
    for (let i = 0; i < numberStr.length; i += 1) {
      const digit = numberStr[i];

      if (digit >= '0' && digit <= '9') {
        translatedNumber += i18nInstance
          ? i18nInstance.t(`numbers.${digit}`)
          : i18n.t(`numbers.${digit}`);
      } else translatedNumber += digit;
    }
    return translatedNumber.trim();
  } catch (error) {
    console.error(error);
    return '';
  }
}

export default numberTranslator;
