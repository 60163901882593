import { fade, darken } from "@material-ui/core/styles/colorManipulator";

const style = (theme) => ({
  cover: {
    "& $name, & $subheading": {
      color: theme.palette.common.white,
    },
    zIndex:999,
    position: "relative",
    width: "100%",
    overflow: "hidden",
    height: 480,
    backgroundColor:
      theme.palette.type === "dark"
        ? darken(theme.palette.primary.dark, 0.8)
        : theme.palette.primary.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    backgroundSize: "cover",
    textAlign: "center",
    boxShadow: theme.shadows[7],
    backgroundPosition: "bottom center",
    borderRadius: theme.rounded.medium,
  },
  avatar: {
    margin: `0 auto ${theme.spacing(2)}px`,
    width: 120,
    height: 120,
    boxShadow: theme.glow.medium,
  },
  content: {
    background: fade(theme.palette.secondary.main, 0.3),
    height: '100%',
    width: '100%',
    color: theme.palette.common.white,
    padding: `70px ${theme.spacing(3)}px 30px`,
    '& label': {
      top: '116px',
      borderRadius: '100%',
      zIndex: '999',
      backgroundColor: 'whitesmoke',
      height: '30px',
      width: '30px',
      minWidth: '30px',
      marginLeft: '84px',
    },
  },
  profileTab: {
    marginTop: -72,
    borderRadius: `0 0 ${theme.rounded.medium} ${theme.rounded.medium}`,
    background: fade(theme.palette.background.paper, 0.8),
    position: 'relative',
  },
  
});

export default style;
