import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import logo from "../images/logo_black.svg";
import brand from "../api/dummy/brand";
import styles from "./landingStyle-jss";
import { Link as RouterLink } from "react-router-dom";
import { TranslationContext } from "../context";

function Decoration(props) {
  const { classes } = props;
  return (
    <div>
      <svg fill="#fff" className={classes.footerDecoration}>
        <use xlinkHref="/images/decoration/petal5.svg#Petal-Bottom" />
      </svg>
    </div>
  );
}

Decoration.propTypes = {
  classes: PropTypes.object.isRequired,
};

const DecorationStyled = withStyles(styles)(Decoration);

class Footer extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <footer className={classes.footer} style={{ paddingTop: "40px" }}>
        <DecorationStyled />
        <div className={classes.container}>
          <div className={classes.spaceContainer}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={classes.brand}
            >
              <img src={logo} alt={brand.name} />
            </div>
            <nav>
              <ul style={{ padding: "0px" }}>
                {t("Links", { returnObjects: true }).map((item, index) => (
                  <RouterLink
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    to={item.url}
                    key={index}
                  >
                    <Button>{t(item.name)}</Button>
                  </RouterLink>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        <div className={classes.copyright}>
          <div className={classes.container}>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);
  return <Footer {...props} t={t} />;
};

export default withStyles(styles)(Wrapper);
