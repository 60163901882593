import { Box, Chip, withStyles } from "@material-ui/core";
import React from "react";
import styles from "./timeline-jss";

const FilterHashTag = ({ classes, selectedHashTag, setSelectedHashTag }) => {
  return (
    <>
      <Box className={classes.mainContainer}>
        
          
            {selectedHashTag && (
              <Chip
              className={classes.hashTagChip}
                size="small"
                label={`${selectedHashTag}`}
                color="secondary"
                onDelete={() => setSelectedHashTag("")}
              />
            )}
        </Box>
    </>
  );
};

export default withStyles(styles)(FilterHashTag);
