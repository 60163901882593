import axios from "axios";
import { Buffer } from "buffer";
const devMode = false;
const deploymentEndPoint = "https://api.kiddiecove.io";
const PORT = 5000;
const localEndPoint = `http://localhost:${PORT}`;
const axiosInstance = axios.create({
  baseURL: `${devMode ? localEndPoint : deploymentEndPoint}/api/`,
  headers: {
    Authorization: `Basic ${Buffer.from("kiddieCove*dev:5426").toString(
      "base64"
    )}`,
  },
});

export default axiosInstance;
