import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PricingCard from "../Cards/PricingCard";
import Title from "./Title";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";
import numberTranslator from "../utils/numberTranslator";

class Pricing extends React.Component {
  render() {
    const { classes, slideMode, t } = this.props;
    return (
      <div className={classes.pricing}>
        <div className={slideMode ? classes.fullWidth : classes.container}>
          <Title
            title={t("Pricing")}
            desc={t("pricingDesc")}
            align="center"
            monocolor={slideMode && true}
          />
          <Grid container className={classes.root} spacing={5}>
            <Grid item md={4} xs={12}>
              <PricingCard
                title={t("basic")}
                price={`${numberTranslator("$50")}`}
                tier="free"
                feature={[
                  `${t("users")}: ${t("upTo")} ${numberTranslator(100)}`,
                  `${t("storage")}: ${numberTranslator("10GB")}`,
                  `${t("feeManagementPlan")}`,
                  `${t("schoolTimeline")}`,
                  `${t("announcementPage")}`,
                  `${t("basicTracking")}`,
                ]}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <PricingCard
                title={t("standard")}
                price={`${numberTranslator("$100")}`}
                tier="cheap"
                feature={[
                  `${t("users")}: ${t("upTo")} ${numberTranslator(200)}`,
                  `${t("storage")}: ${numberTranslator("50GB")}`,
                  `${t("feeManagementPlan")}`,
                  `${t("schoolTimeline")}`,
                  `${t("announcementPage")}`,
                  `${t("albumsSection")}`,
                ]}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <PricingCard
                title={t("premium")}
                price={`${numberTranslator("$200")}`}
                tier="expensive"
                feature={[
                  `${t("users")}: ${t("unlimited")}`,
                  `${t("storage")}: ${numberTranslator("200GB")}`,
                  `${t("feeManagementPlan")}`,
                  `${t("schoolTimeline")}`,
                  `${t("announcementPage")}`,
                  `${t("albumsSection")}`,
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
};

Pricing.defaultProps = {
  slideMode: false,
};
const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);
  return <Pricing {...props} t={t} />;
};

export default withStyles(styles)(Wrapper);
