const styles = (theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.type === "dark" ? "#292929" : "white",
    borderRadius: "8px",
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
  },
  postDescriptionRoot: {
    paddingTop: "0px !important",
    marginTop: "-20px",
    paddingInline: "20px !important",
  },
  hashTagChip: {
    height: "auto",
    direction: "initial",
    "& span": {
      textWrap: "wrap",
      padding: "6px",
    },

    "& .MuiChip-deleteIcon": {
      margin: theme.direction === "rtl" ? "0px 5px" : "0px 5px",
    },
  },
  content: {
    flex: "1 0 auto",
  },
  adsContainer: {
    display: "flex",
    gap: "25px",
    flexDirection: "column",
    padding: "10px 0px",
  },
  noPostFound: {
    height: 1200,
    with: 600,
    marginInline: "auto",
    marginTop: "5px",
    [theme.breakpoints.up("xs")]: {
      width: 300,
    },
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },

  cardSocmed: {
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
    marginBottom: theme.spacing(3),
    position: "relative",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    maxHeight: 600,
    maxWidth: 1000,
  },
  timeline: {
    marginTop: "0px",
    paddingLeft: "0px",
    "& .infinite-scroll-component ": {
      overflow: "hidden !important",
    },

    position: "relative",
    "& li": {
      position: "relative",
      display: "block",
    },
    "& time": {
      top: 70,
      left: 20,
      position: "absolute",
      textAlign: "center",
      background: theme.palette.common.white,
      boxShadow: theme.shadows[3],
      padding: "4px 40px 4px 15px",
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
    },
    "& $iconBullet": {
      position: "absolute",
      borderRadius: "50%",
      top: 20,
      width: 40,
      height: 40,
      background: theme.palette.primary.main,
      boxShadow: theme.shadows[5],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: 20,
      "& $icon": {
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  descriptions: {
    fontFamily:'Open Sans',
    margin: "0px",
    "& span": {
      cursor: "default",
    },
  },
});

export default styles;
