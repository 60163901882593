const style = (theme) => ({
  paper: {
    padding: 5,
    position: "sticky",
    top: "5rem",
  },
  breakSpace: {
    whiteSpace: "wrap",
    overflow: "hidden",
    wordBreak: "break-all",
  },
  titleText: {
    fontSize: "1.5rem",
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
  },
  mainContainer: {
    borderRadius: "8px",
    padding: "1rem",
  },
});

export default style;
