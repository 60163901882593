import React from "react";
import {
  Modal,
  makeStyles,
  CardMedia,
  Backdrop,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  media: {
    width: "100%",
    maxHeight: "80vh",
    objectFit: "contain",
  },
  removeBtn: {
    position: "absolute",
    opacity: 1,
    backgroundColor: "rgba(50, 50, 50, 0.3)",
    borderRadius: "50%",
    top: "6px",
    right: "6px",
  },
  icon: {
    fill: "white",
  },
}));

const ImageModal = ({ open, setOpen, image }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div className={classes.removeBtn}>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="20px" className={classes.icon} />
          </IconButton>
        </div>
        <CardMedia
          component="img"
          src={image}
          alt="Modal Image"
          className={classes.media}
        />
      </div>
    </Modal>
  );
};

export default ImageModal;
