import React, { useContext, useRef, useState } from "react";
import Background from "../../layout/Background";
import { Box, Typography, withStyles } from "@material-ui/core";
import styles from "../landingStyle-jss";
import { TranslationContext } from "../../context";
import AboutCard from "./AboutCard";

const About = ({ classes }) => {
  const { t } = useContext(TranslationContext);
  const cardList = [
    {
      id: 3,
      title: "title4",
      description: "description4",
      videoUrl: "https://www.youtube.com/embed/7OWGOYVqStU?si=R61p-3uSuirxXuu7",
    },
    {
      id: 0,
      title: "title1",
      description: "description1",
      videoUrl: "https://www.youtube.com/embed/llxcJPrxkLU?si=SrScCy9aHbP75uek",
    },
    {
      id: 1,
      title: "title2",
      description: "description2",
      videoUrl: "https://www.youtube.com/embed/2Dn9UVDCTAY?si=gSxlk3nvOtSg-nmd",
    },
    {
      id: 2,
      title: "title3",
      description: "description3",
      videoUrl: "https://www.youtube.com/embed/GvBvnuKH4wY?si=iATgc8Mc5cxp6grR",
    },
  ];

  return (
    <>
      <section id="banner">
        <Background classes={classes}>
          <Typography component="h2" variant="h2" gutterBottom>
            {t("about.title")}
          </Typography>
          <Box className={classes.mainImage} mt={2}>
            <img
              src={"/images/aboutUsCover.svg"}
              alt="Privacy Policy"
              style={{ zIndex: 99, width: "100%", height: "450px" }}
            />
          </Box>
        </Background>
      </section>
      <section>
        <Box
          paddingTop={{ sm: "0px", md: "2rem" }}
          paddingBottom={"5rem"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gridGap={{ xs: "1rem", sm: "1rem", md: "4rem" }}
          flexDirection={"column"}
        >
          {cardList.length > 0 &&
            cardList.map((item) => {
              return (
                <AboutCard
                  title={item.title}
                  description={item.description}
                  videoLink={item.videoUrl}
                />
              );
            })}
        </Box>
      </section>
    </>
  );
};

export default withStyles(styles)(About);
