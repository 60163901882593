import { AppBar, Hidden, Tab, Tabs, withStyles } from "@material-ui/core";
import React, { useContext } from "react";
import style from "./profile-jss";
import EventIcon from "@material-ui/icons/Event";
import { TranslationContext } from "../../context";
const CoverTabs = ({ classes, value, setValue }) => {
  const handleValue = (tag, tabId) => {
    setValue(tabId);
  };
  const { t } = useContext(TranslationContext);


  
  return (
    <>
      <AppBar position="static" className={classes.profileTab}>
        <Hidden mdUp>
          <Tabs
            value={value}
            onChange={handleValue}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab icon={<EventIcon />} label={t("timeline.timeline")} />,
          </Tabs>
        </Hidden>
        <Hidden smDown>
          <Tabs
            value={value}
            onChange={handleValue}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab icon={<EventIcon />} label={t("timeline.timeline")} />,
          </Tabs>
        </Hidden>
      </AppBar>
      
    </>
  );
};

export default withStyles(style)(CoverTabs);
