import React, { useContext } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Title from "./Title";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";
import axiosInstance from "../api/axiosInstance";
import { withRouter } from "react-router-dom";
import { RoleType } from "../utils/constants";

function ParallaxDeco(props) {
  const { classes } = props;
  return (
    <div className={classes.parallaxWrap}>
      <ParallaxProvider>
        <Parallax
          offsetYMax={180}
          offsetYMin={-200}
          slowerScrollRate
          tag="figure"
        >
          <svg
            fill="#fff"
            className={classNames(
              classes.parallaxVertical,
              classes.parallaxLineSide3
            )}
          >
            <use xlinkHref="/images/decoration/lineSide3.svg#Line-Side3" />
          </svg>
        </Parallax>
        <Parallax
          offsetYMax={100}
          offsetYMin={-200}
          slowerScrollRate
          tag="figure"
        >
          <svg
            fill="#fff"
            className={classNames(
              classes.parallaxVertical,
              classes.parallaxLineSide4
            )}
          >
            <use xlinkHref="/images/decoration/lineSide4.svg#Line-Side4" />
          </svg>
        </Parallax>
      </ParallaxProvider>
    </div>
  );
}

ParallaxDeco.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ParallaxDecoStyled = withStyles(styles)(ParallaxDeco);

class Technology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      technologies: [],
    };
  }

  componentDidMount() {
    this.fetchTechnologies();
  }

  fetchTechnologies = async () => {
    try {
      const response = await axiosInstance.get("tenant/featured/partners");
      const fetchedPosts = response.data.data;
      this.setState({ technologies: fetchedPosts });
    } catch (error) {
      console.error("Error fetching technologies:", error);
      this.setState({ loading: false });
    }
  };

  handleNavigate = () => {
    this.props.history.push("/timeline");
  };

  handleTimeline = (id) => {
    this.props.history.push(`/timeline/${id}?role_type=${RoleType.ROOT}`);
  };

  render() {
    const { classes, slideMode, t } = this.props;
    const { technologies } = this.state;

    return (
      <div className={classes.tech}>
        {!slideMode && <ParallaxDecoStyled />}
        <div className={slideMode ? classes.fullWidth : classes.container}>
          <Title
            title={t("The technologies")}
            desc={t("technologiesDesc")}
            align="center"
            monocolor={slideMode && true}
          />
          <Hidden mdUp>
            <Button
              onClick={this.handleNavigate}
              variant="contained"
              size="medium"
              color="secondary"
              style={{ marginBottom: "30px" }}
            >
              {t("Request To Implement Technology")}
            </Button>
          </Hidden>
          <Grid container className={classes.root} spacing={3}>
            <Grid item sm={4} xs={12}>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img
                    src={technologies[0]?.avatar}
                    alt={technologies[0]?.org_name}
                  />
                </figure>
                <Typography
                  onClick={() => this.handleTimeline(technologies[0]?._id)}
                  variant="h5"
                  className={classes.webpack}
                >
                  {technologies[0]?.org_name}
                </Typography>
              </div>

              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img
                    src={technologies[1]?.avatar}
                    alt={technologies[1]?.org_name}
                  />
                </figure>
                <Typography
                  onClick={() => this.handleTimeline(technologies[1]?._id)}
                  variant="h5"
                  className={classes.webpack}
                >
                  {technologies[1]?.org_name}
                </Typography>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={classes.centerTech}>
                <div
                  className={classNames(
                    classes.wool,
                    slideMode && classes.slideMode
                  )}
                >
                  <figure className={classes.figure}>
                    <img
                      src={technologies[2]?.avatar}
                      alt={technologies[2]?.org_name}
                    />
                  </figure>
                  <Typography
                    onClick={() => this.handleTimeline(technologies[2]?._id)}
                    variant="h5"
                    className={classes.webpack}
                  >
                    {technologies[2]?.org_name}
                  </Typography>
                </div>

                <Hidden smDown>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={this.handleNavigate}
                    color="secondary"
                  >
                    {t("Request To Implement Technology")}
                  </Button>
                </Hidden>

                <div
                  className={classNames(
                    classes.wool,
                    slideMode && classes.slideMode
                  )}
                >
                  <figure className={classes.figure}>
                    <img
                      src={technologies[3]?.avatar}
                      alt={technologies[3]?.org_name}
                    />
                  </figure>
                  <Typography
                    onClick={() => this.handleTimeline(technologies[3]?._id)}
                    variant="h5"
                    className={classes.webpack}
                  >
                    {technologies[3]?.org_name}
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item sm={4} xs={12}>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img
                    src={technologies[4]?.avatar}
                    alt={technologies[4]?.org_name}
                  />
                </figure>
                <Typography
                  onClick={() => this.handleTimeline(technologies[4]?._id)}
                  variant="h5"
                  className={classes.webpack}
                >
                  {technologies[4]?.org_name}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img
                    src={technologies[5]?.avatar}
                    alt={technologies[5]?.org_name}
                  />
                </figure>
                <Typography
                  onClick={() => this.handleTimeline(technologies[5]?._id)}
                  variant="h5"
                  className={classes.webpack}
                >
                  {technologies[5]?.org_name}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Technology.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
};

Technology.defaultProps = {
  slideMode: false,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);

  return <Technology {...props} t={t} />;
};

export default withRouter(withStyles(styles)(withRouter(Wrapper)));
