import React, { useContext, useEffect, useState } from "react";
import { Paper, Typography, Box, withStyles } from "@material-ui/core";
import styles from "./papperStyle-jss";
import classNames from "classnames";
import { Skeleton } from "@material-ui/lab";
import { TranslationContext } from "../../context";
import axiosInstance from "../../api/axiosInstance";

const TrendingHashtags = ({ classes, setSelectedHashTag }) => {
  const { t } = useContext(TranslationContext);
  const [hashTag, setHashTag] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const fetchTrendingHashtags = async () => {
      try {
        const response = await axiosInstance.get(
          "/post/get/public/trending/hashtags"
        );
        setHashTag(response.data.data);
      } catch (error) {
        console.error("Error fetching trending hashtags:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchTrendingHashtags();
  }, []);
  return (
    <>
      <Paper
        className={classNames(classes.root)}
        elevation={0}
        style={{ position: "sticky", top: "70px" }}
      >
        <div className={classes.descBlock}>
          <span className={classes.iconTitle}>
            <img src="/images/trend.svg" alt="trend" width={30} height={30} />
          </span>
          <div className={classes.titleText}>
            <Typography className={classes.title}>
              {t("timeline.TrendsForYou")}
            </Typography>
          </div>
        </div>
        <section>
          <Box className={classes.trendingList}>
            {!loader
              ? hashTag.length
                ? hashTag.map((item) => (
                    <Box
                      className={classes.hashtagContainer}
                      key={item.hashtag}
                    >
                      <Typography
                        color="primary"
                        className={classes.hashtagHeading}
                        onClick={() => setSelectedHashTag(item.hashtag)}
                      >
                        {item.hashtag}
                      </Typography>
                      <Typography
                        className={classes.hashTagCounter}
                        color="textPrimary"
                      >
                        {`${t(`numbers.${item.count}`)} ${
                          item.count > 1
                            ? t("timeline.posts")
                            : t("timeline.post")
                        }`}
                      </Typography>
                    </Box>
                  ))
                : t("timeline.NoHashTagFound")
              : Array.from(new Array(7)).map((_, index) => (
                  <Box className={classes.skeletonContainer} key={index}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes.skeleton}
                      height={30}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={40}
                      height={30}
                    />
                  </Box>
                ))}
          </Box>
        </section>
      </Paper>
    </>
  );
};

export default withStyles(styles)(TrendingHashtags);
