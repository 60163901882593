import React, { useEffect, useState } from "react";
import Background from "../../layout/Background";
import { Box, withStyles } from "@material-ui/core";
import styles from "../landingStyle-jss";
import axiosInstance from "../../api/axiosInstance";
import { useParams, useLocation } from "react-router-dom";
import AdSection from "../Timeline/AdSection";
import Post from "./Post";
import { Skeleton } from "@material-ui/lab";
import About from "./About";
import { EntityType } from "../../utils/constants";
import Cover from "../Profile/Cover";
import CoverTabs from "../Profile/CoverTabs";

const FeaturedTimeline = ({ classes }) => {
  let { search } = useLocation();
  const [value, setValue] = useState(0);
  const query = new URLSearchParams(search);
  const entityType = query.get("entity_type");
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [tenant, setTenant] = useState(null);
  const [school, setSchool] = useState(null);
  useEffect(() => {
    if (
      (entityType && entityType === EntityType.TENANT) ||
      entityType === EntityType.SCHOOL
    ) {
      if (entityType === EntityType.TENANT) {
        setSchool(null);
        getTenant();
      } else {
        setTenant(null);
        getSchool();
      }
    }
  }, [entityType]);

  const getTenant = async () => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(`tenant/public/get/${id}`);
      setLoader(false);
      setTenant(response.data.data);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const getSchool = async () => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(`school/public/get/${id}`);
      setLoader(false);
      setSchool(response.data.data);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  return (
    <>
      <Box>
        <section id="banner" className={classes.tabsSection}>
          <Background classes={classes} slideMode={false} gradient={true}>
            {loader ? (
              <>
                <Skeleton
                  width={"100%"}
                  height={"450px"}
                  component={"h2"}
                  style={{
                    marginInline: "auto",
                  }}
                  variant="rect"
                />
              </>
            ) : (
              <>
                <Box className={classes.mainTimelineContainer}>
                  <Box style={{ width: "100%", zIndex: 999, padding: "1rem" }}>
                    <Cover
                      cover={tenant ? tenant.cover : school.cover}
                      avatar={tenant ? tenant.avatar : school.avatar}
                      name={tenant ? tenant.org_name : school.title}
                    />
                    <CoverTabs value={value} setValue={setValue} id={id} />
                  </Box>
                </Box>
              </>
            )}
          </Background>
        </section>
        <section>
          <Box className={classes.timelineContainer}>
            <Box className="addSection">
              <AdSection />
            </Box>
            <Box className="post">
              <>
                <Box className={classes.po}>
                  {id && entityType && (
                    <Post entityId={id} entityType={entityType} />
                  )}
                </Box>
              </>
            </Box>
            <Box className="hashtag">
              {school ? (
                <About data={school} isTenant={false} />
              ) : (
                tenant && <About data={tenant} isTenant={true} />
              )}
            </Box>
          </Box>
        </section>
      </Box>
    </>
  );
};

export default withStyles(styles)(FeaturedTimeline);
