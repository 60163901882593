import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { TranslationContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: "20px",
    gap: "1rem",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "28px",
    marginBottom: "10px",
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "100%",
    },
  },
  description: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "20px",
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "100%",
    },
  },
  media: {
    width: "100%",
    height: "450px",
    maxWidth: "800px",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      height: "250px", 
    },
  },
}));
const AboutCard = ({
  title,
  description,
  videoLink,
}) => {
  const classes = useStyles();
  const { t } = useContext(TranslationContext);

  return (
    <>
      <Box className={classes.container}>
        <Typography color="primary" className={classes.title}>
          {t(`about.cards.${title}`)}
        </Typography>
        <Typography className={classes.description}>
          {t(`about.cards.${description}`)}
        </Typography>
      
        <iframe className={classes.media} src={videoLink}></iframe>
      </Box>
    </>
  );
};

export default AboutCard;
