import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Email } from "@material-ui/icons";
import React, { useContext } from "react";
import style from "./featureTimeline-jss";
import PhoneIcon from "@material-ui/icons/Phone";
import ApartmentIcon from "@material-ui/icons/Apartment";
import RoomIcon from "@material-ui/icons/Room";
import { TranslationContext } from "../../context";
const About = ({ classes, data = null, isTenant = false }) => {
  const { t } = useContext(TranslationContext);
  return (
    <Paper className={classes.paper}>
      <Box>
        <Box className={classes.mainContainer}>
          <Typography variant="h6" className={classes.titleText}>
            {t("about.title")}
          </Typography>
          <Divider className={classes.Divider} />
          <List dense className={classes.profileList}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ApartmentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.breakSpace}
                primary={
                  isTenant ? t("about.tenantName") : t("about.schoolName")
                }
                secondary={(isTenant ? data.org_name : data.name) || null}
              />
            </ListItem>
            {!isTenant && (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ApartmentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.breakSpace}
                  primary={t("about.branchName")}
                  secondary={(data && data.branch_name) || null}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Email />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.breakSpace}
                primary={t("about.email")}
                secondary={(data && data.email) || null}
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PhoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.breakSpace}
                primary={t("about.phoneNumber")}
                secondary={
                  "+" +
                  data.phone_number.dialCode +
                  " " +
                  data.phone_number.phone
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <RoomIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t("about.address")}
                className={classes.breakSpace}
                secondary={
                  isTenant
                    ? data.address_id.address_details
                    : data.address.address_details
                }
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Paper>
  );
};

export default withStyles(style)(About);
