export const RoleType = {
  ROOT: "ROOT",
  SCHOOL_ADMIN: "SCHOOL_ADMIN",
  PARENT: "PARENT",
  TEACHER: "TEACHER",
  DRIVER: "DRIVER",
  KID: "KID",
};

export const EntityType = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  SCHOOL_ADMIN: "SCHOOL_ADMIN",
  PARENT: "PARENT",
  TEACHER: "TEACHER",
  DRIVER: "DRIVER",
  KID: "KID",
  SCHOOL: "SCHOOL",
  TENANT: "TENANT",
};
