import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { TranslationContext } from "../../context";
import axiosInstance from "../../api/axiosInstance";
import PostCard from "../Timeline/PostCard";
import styles from "../Timeline/timeline-jss";
import PostSkeleton from "../Timeline/PostSkeleton";
import { EntityType } from "../../utils/constants";

const Post = ({ classes, entityId, entityType }) => {
  const { t } = useContext(TranslationContext);

  const [posts, setPosts] = useState([]);
  const [postLoader, setPostLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const size = 5;

  const fetchPosts = async () => {
    try {
      setPostLoader(true);
      const params = new URLSearchParams();
      params.append("page", 1);
      params.append("size", size);
      params.append("entity_id", entityId);
      params.append("entity_type", entityType);

      const response = await axiosInstance.get(
        `post/public?${params.toString()}`
      );
      const fetchedPosts = response.data.data;

      setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);

      if (fetchedPosts.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setPostLoader(false);
    }
  };

  const fetchNextPostData = async () => {
    try {
      const nextPage = page + 1;

      const params = new URLSearchParams();
      params.append("page", nextPage);
      params.append("size", size);
      params.append("entity_id", entityId);
      params.append("entity_type", entityType);

      const response = await axiosInstance.get(
        `post/public?${params.toString()}`
      );
      const fetchedPosts = response.data.data;
      setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);

      if (fetchedPosts.length === 0) {
        setHasMore(false);
      } else {
        setPage(nextPage);
      }
    } catch (error) {
      console.error("Error occurred while loading posts:", error);
    }
  };

  useEffect(() => {
    if (entityId && entityType) {
      if (
        entityType === EntityType.TENANT ||
        entityType === EntityType.SCHOOL
      ) {
        setPage(1);
        setPosts([]);
        setHasMore(true);
        fetchPosts();
      }
    }
  }, [entityId, entityType]);

  return (
    <div style={{ width: "100%" }}>
      {postLoader ? (
        <Box style={{ width: "100%" }}>
          {[...Array(size)].map((x, i) => (
            <PostSkeleton />
          ))}
        </Box>
      ) : posts.length ? (
        <>
          <ul className={classes.timeline}>
            {posts && (
              <InfiniteScroll
                dataLength={posts.length}
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "18px",
                      color: "#999",
                    }}
                  >
                    {t("timeline.noMorePost")}
                  </p>
                }
                next={fetchNextPostData}
                scrollThreshold="200px"
                hasMore={hasMore}
                loader={[...Array(size)].map((x, i) => (
                  <PostSkeleton />
                ))}
                scrollableTarget="roomContainer"
              >
                {posts &&
                  posts.map((data) => (
                    <>
                      <PostCard data={data} />
                    </>
                  ))}
              </InfiniteScroll>
            )}
          </ul>
        </>
      ) : (
        <Box className={classes.noPostFound}>
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
            }}
            alt={"no post"}
            src={"/images/noPost.svg"}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding="3px"
          >
            <Typography
              variant="h6"
              color="primary"
              style={{ textAlign: "center" }}
              className={classes.responsiveText}
            >
              {t("timeline.noPostFound")}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default withStyles(styles)(Post);
