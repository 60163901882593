import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Title from "./Title";
import styles from "./landingStyle-jss";
import emailjs from "@emailjs/browser";
import Snackbar from "@material-ui/core/Snackbar";
import {
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TranslationContext } from "../context";
const EMAIL_REGEX = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g;
const EMAIL_HELPER_TEXT = "valid email";
const FIELD_MIN_LENGTH = 1;
const NAME_FIELD_MAX_LENGTH = 100;
const MESSAGE_FIELD_MAX_LENGTH = 5000;

class Contact extends React.Component {
  theme = createTheme({
    overrides: {
      MuiAlert: {
        action: {
          paddingLeft: "0px",
          marginRight: "10px",
        },
        icon: {
          marginLeft: "20px",
        },
      },
    },
  });

  state = {
    name: "",
    email: "",
    message: "",
    messages: "",
    showCircularProgress: false,
    severity: "success",
    disableButton: false,
    emailValid: false,
    nameValid: false,
    messagesValid: false,
  };
  handleChange = (name) => (e) => {
    console.log("name", name, e.target.value);
    this.setState({
      [name]: e.target.value,
    });

    if (name === "email") {
      this.emailValidation(e.target.value);
    } else if (name === "name" || name === "messages") {
      this.fieldValidation(name, e.target.value);
    }
  };
  sendEmail = (e) => {
    e.preventDefault();
    const { t } = this.props;

    if (
      this.state.name.trim().length < FIELD_MIN_LENGTH ||
      this.state.email === "" ||
      this.state.messages.trim().length < FIELD_MIN_LENGTH
    ) {
      this.setState({
        message: t("Fill All fields"),
        severity: "error",
        disableButton: false,
      });
    } else {
      if (this.state.email.match(EMAIL_REGEX)) {
        this.setState({ emailValid: false });
        this.setState({
          showCircularProgress: true,
          disableButton: true,
        });

        emailjs
          .sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            "#contactform",
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then((res) => {
            if (res.status === 200) {
              this.clear();
              this.setState({
                message: t("Email sent success"),
                showCircularProgress: false,
                disableButton: false,
              });
            }
          })
          .catch((e) =>
            this.setState({
              message: t("Email failed"),
              showCircularProgress: false,
              severity: "error",
              disableButton: false,
            })
          );
      } else {
        this.setState({
          showCircularProgress: false,
          disableButton: false,
          message: t("Enter Valid Email"),
          severity: "error",
        });
      }
    }
  };
  emailValidation = (mail) => {
    if (!mail.match(EMAIL_REGEX)) {
      this.setState({ emailValid: true });
    } else {
      this.setState({ emailValid: false });
    }
  };
  fieldValidation = (name, value) => {
    const trimmedValue = value.trim();
    if (name === "name") {
      const isValid =
        trimmedValue.length >= FIELD_MIN_LENGTH &&
        trimmedValue.length <= NAME_FIELD_MAX_LENGTH;
      this.setState({
        [`${name}Valid`]: !isValid,
      });
    } else {
      const isValid =
        trimmedValue.length >= FIELD_MIN_LENGTH &&
        trimmedValue.length <= MESSAGE_FIELD_MAX_LENGTH;
      this.setState({
        [`${name}Valid`]: !isValid,
      });
    }
  };
  handleClose = () => {
    this.setState({ message: "" });
  };

  clear = () => {
    this.setState({
      name: "",
      email: "",
      messages: "",
      message: "",
      showCircularProgress: false,
      severity: "success",
      disableButton: false,
    });
  };
  render() {
    const { classes, slideMode, t } = this.props;
    const { name, email, messages, nameValid, messagesValid } = this.state;
    return (
      <div
        className={classNames(classes.contact, !slideMode && classes.withBg)}
        style={{ marginTop: "3rem" }}
      >
        <div className={classes.container}>
          <form id="contactform">
            <div className={classes.contactBubble}>
              <Title title={t("Contact us")} align="left" nomargin />
              <Typography component="p" className={classes.contactText}>
                {t("contactUsDesc")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      id="standard-name"
                      label={t("Name")}
                      className={classes.textField}
                      value={name}
                      required
                      onChange={this.handleChange("name")}
                      margin="normal"
                      name="from_name"
                      inputProps={{ maxLength: NAME_FIELD_MAX_LENGTH }}
                      helperText={
                        nameValid ? t("validationMessage.contactUs.name") : ""
                      }
                      error={nameValid}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      id="standard-email"
                      label={t("E-mail")}
                      className={classes.textField}
                      value={email}
                      required
                      onChange={this.handleChange("email")}
                      margin="normal"
                      name="from_email"
                      type="email"
                      helperText={
                        this.state.emailValid
                          ? this.props.t(EMAIL_HELPER_TEXT)
                          : ""
                      }
                      error={this.state.emailValid}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      id="standard-multiline-flexible"
                      label={t("Messages")}
                      multiline
                      rows="4"
                      value={messages}
                      onChange={this.handleChange("messages")}
                      className={classes.textField}
                      margin="normal"
                      name="message"
                      required
                      inputProps={{ maxLength: MESSAGE_FIELD_MAX_LENGTH }}
                      helperText={
                        messagesValid
                          ? t("validationMessage.contactUs.message")
                          : ""
                      }
                      error={messagesValid}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.btnArea}>
                <Button
                  style={{ width: `100%` }}
                  variant="contained"
                  size="large"
                  className={classes.button}
                  color="secondary"
                  disabled={this.state.disableButton}
                  onClick={this.sendEmail}
                  type="submit"
                >
                  {this.state.showCircularProgress ? (
                    <CircularProgress size={30} thickness={2} />
                  ) : (
                    t("Send")
                  )}
                </Button>

                <ThemeProvider theme={this.theme}>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    open={this.state.message !== ""}
                    onClose={() => this.handleClose()}
                  >
                    <Alert
                      severity={this.state.severity}
                      autoHideDuration={4000}
                      onClose={() => this.handleClose()}
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          onClick={this.handleClose}
                          style={{
                            padding: "0px",
                            margin: "0px",
                            minWidth: "20px",
                            marginRight: "10px",
                          }}
                        >
                          X
                        </Button>
                      }
                      sx={{ width: "100%" }}
                      variant="filled"
                    >
                      {this.state.message}
                    </Alert>
                  </Snackbar>
                </ThemeProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
};

Contact.defaultProps = {
  slideMode: false,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);
  return <Contact {...props} t={t} />;
};

export default withStyles(styles)(Wrapper);
