import i18n from './i18n';
import numberTranslator from './numberTranslator';

function translateDate(dateStr, splitChar, i18nInstance = null) {
  // allowed formats : "18-Jul-2024" and "Jul-2024"
  try {
    const dateParts = dateStr.split(splitChar);

    let day;
    let month;
    let year;
    if (dateParts.length === 3) {
      [day, month, year] = dateParts;
    } else if (dateParts.length === 2) {
      [month, year] = dateParts;
    } else {
      throw new Error('Invalid date format');
    }

    const urduMonth = i18nInstance
      ? i18nInstance.t(`abbreviatedMonths.${month}`)
      : i18n.t(`abbreviatedMonths.${month}`);

    if (day) {
      return `${numberTranslator(
        day,
        i18nInstance
      )}${splitChar}${urduMonth}${splitChar}${numberTranslator(
        year,
        i18nInstance
      )}`;
    }
    return `${urduMonth}${splitChar}${numberTranslator(year, i18nInstance)}`;
  } catch (error) {
    return '';
  }
}

export default translateDate;
