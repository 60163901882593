import { Avatar, Typography, withStyles } from "@material-ui/core";
import React from "react";
import style from "./profile-jss";

const Cover = ({ cover = null ,avatar = null, name = null, classes }) => {
  return (
    <>
      <div className={classes.cover} style={{ backgroundImage: `url('https://res.cloudinary.com/hamuz/image/upload/v1728366387/jh50gu8lhqsatnldw5pv.jpg')` }}>
        <div className={classes.content}>
          <Avatar alt={name} src={avatar} className={classes.avatar}>
            {avatar === null && <>{name}</>}
          </Avatar>

          <Typography variant="h4" className={classes.name} gutterBottom>
            {name}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default withStyles(style)(Cover);
