import moment from "moment";
import translateDate from "./translateDate";

export default (dateStr, t, i18n) => {
  const dateObj = new Date(dateStr);
  const currentDate = new Date();
  const timeDifference = currentDate - dateObj;
  const msInMinute = 60 * 1000;
  const msInHour = 60 * msInMinute;
  const msInDay = 24 * msInHour;
  const dateFormat = "DD-MMM-YYYY";

  const pluralize = (count, singular, plural) =>
    `${t("numbers." + count)} ${
      count === 1
        ? t("DateAndTime." + singular)
        : t("DateAndTime." + plural)
    } ${t("DateAndTime.ago")}`;

  if (timeDifference < msInMinute) {
    return t("DateAndTime.justNow");
  }

  const getRelativeTimeString = (diff, singular, plural) => {
    const count = Math.floor(timeDifference / diff);
    return pluralize(count, singular, plural);
  };

  if (timeDifference < msInHour) {
    return getRelativeTimeString(msInMinute, "minute", "minutes");
  }

  if (timeDifference < msInDay) {
    return getRelativeTimeString(msInHour, "hour", "hours");
  }

  if (timeDifference < 7 * msInDay) {
    const optionsWeekday = { weekday: "long" };
    return dateObj.toLocaleDateString(i18n.language, optionsWeekday);
  }

  return translateDate(moment(dateObj).format(dateFormat), "-");
};
