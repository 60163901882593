import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      direction: 'initial',
      maxWidth: '100%',
    },
  },
  hashTagText: {
    fontWeight: 600,
  },
}));

const HashtagUi = ({ loader, hashTags }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {loader ? (
        <Skeleton width="100%" variant="text" />
      ) : (
        hashTags &&
        hashTags.map(tag => (
          <Typography
            size="small"
            className={classes.hashTagText}
            key={tag}
            color="secondary"
          >
            {tag}
          </Typography>
        ))
      )}
    </div>
  );
};

HashtagUi.propTypes = {
  loader: PropTypes.bool.isRequired,
  hashTags: PropTypes.array.isRequired,
};

export default HashtagUi;
