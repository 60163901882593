import React, { useContext, useState } from "react";
import { Typography, Box, withStyles } from "@material-ui/core";
import AdSection from "./AdSection";
import Post from "./Post";
import TrendingHashtags from "./TrendingHashtags";
import Background from "../../layout/Background";
import styles from "../landingStyle-jss";
import { TranslationContext } from "../../context";
import FilterHashTag from "./FilterHashTag";

const Timeline = ({ classes }) => {
  const [selectedHashTag, setSelectedHashTag] = useState("");
  const { t } = useContext(TranslationContext);

  return (
    <>
      <section id="banner">
        <Background classes={classes}>
          <Typography component="h2" variant="h2" gutterBottom>
            {t("timeline.schoolsPublicTimeline")}
          </Typography>
          <Box className={classes.mainImage} mt={2}>
            <img
              src={"/images/timeline.svg"}
              alt="Privacy Policy"
              style={{ zIndex: 99, width: "100%", height: "450px" }}
            />
          </Box>
        </Background>
      </section>
      <section>
        <Box className={classes.timelineContainer}>
          <Box className="addSection">
            <AdSection />
          </Box>
          <Box className="post">
            {selectedHashTag && (
              <FilterHashTag
                selectedHashTag={selectedHashTag}
                setSelectedHashTag={setSelectedHashTag}
              />
            )}
            <Post selectedHashTag={selectedHashTag} />
          </Box>
          <Box className="hashtag">
            <TrendingHashtags setSelectedHashTag={setSelectedHashTag} />
          </Box>
        </Box>
      </section>
    </>
  );
};

export default withStyles(styles)(Timeline);
